@import "../../../../../style/configs/breakpoints.css";
@import "../../../../../style/configs/color.css";

.menu {
    font-family: 'Catamaran', sans-serif !important;
    width: 22vw !important;
    margin-top: 1rem;
    border-radius: 12px !important;
    /* margin-left: 1rem;
    margin-right: 1rem !important; */
    .nav-link {
        border-left: 1px solid #ACACAC !important;
        border-right: 1px solid #ACACAC !important;
    }
    /* background-color: $celeste; */
    @media (max-width: $sm) {
        margin-top: 3.5rem !important;
        margin-left: 0 !important;
        width: auto !important;
    }
    @media (max-width: $md) {
        margin-top: 3.5rem !important;
        margin-left: 0 !important;
        /* width: 25vw !important; */
        width: auto !important;
    }
    @media (min-width: $lg) {
        width: 15vw !important;
    }
    /* @media (max-width: $lg) {
        margin-top: 3.5rem !important;
        margin-left: 1rem !important;
        margin-right: 1rem !important;
        width: 15vw !important;
    }
    @media (max-width: $lg) {
        margin-top: 1rem !important;
        margin-left: 1rem !important;
        margin-right: 1rem !important;
        width: 15vw !important;
    } */
}

.icon-menu {
    width: 18px;
}

.icons-part {
    width: 17%;
    margin-left: 0.5rem;
    /* height: 95vh; */
    height: 100%;
    border-radius: 12px !important;
    background-color: white;
}

.items-part {
    /* height: 95vh; */
    height: 100%;
    width: 83%;
    /* border-top-left-radius: 12px !important; */
    /* border-top-right-radius: 12px !important; */
    border-radius: 12px !important;
    margin-left: 0.5rem;
    background-color: white;
    box-shadow: 0px 3px 6px #00000045;
    @media (max-width: $sm) {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }
    @media (min-width: $md) {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }
    @media (min-width: $lg) {
        padding-right: 0.3rem !important;
        padding-left: 0.3rem !important;
    }
    @media (min-width: $xl) {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
}


.main-navbar {
    border-top: 1px solid #ACACAC;
    border-left: 1px solid #ACACAC;
    border-right: 1px solid #ACACAC;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.text-nav {
    font-family: 'Catamaran', sans-serif !important;
    font-size: 20px;
    color: $azul-marino;
    font-weight: bold;
}

.perfil-header {
    height: 40%;
    background-color: white;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.perfil-img {
    height: 6rem;
    width:  6rem;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% auto;
}

.perfil-nombre {
    font-size: 20px;
    text-align: center;
    font-weight: bold;
    padding: 0 0.3rem;
    color: $azul-marino;
}

.perfil-action {
    background-color: white;
    border: none !important;
    box-shadow: none !important ;
}

.menu-items {
    height: 100%;
    background-color: white;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

.header-item {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    border-bottom: 3px solid $azul-claro;
}

.sub-menu-list {
    list-style-type: none;
    padding-left:  0.5rem !important;
    padding-right: 0.5rem !important;
}

.sub-nav-item {
    font-family: 'Catamaran', sans-serif !important;
    font-size: 18px;
    font-weight: bold;
    margin-top:    0.3rem;
    margin-bottom: 0.3rem;
    color: $azul-claro;
}

.sub-item-menu {
    padding-top:    0.3rem;
    padding-bottom: 0.3rem;
    padding-left:   0.5rem;
}

.sub-nav-link.active {
    .sub-item-menu {
        border-radius: 10px;
        background-color: $verde !important;
        color: white !important;
    }
}

.item-menu {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 0 !important;
}

.cola-menu {
    border-bottom: 1px solid #ACACAC;
    border-left: 1px solid #ACACAC;
    border-right: 1px solid #ACACAC;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;

}

.nav-link, .nav-item, .item-menu, .accordion__item, .accordion__heading, .accordion__button {
    outline: none !important;
}

.img_azul_display{
    visibility: visible !important;
    display: inline !important;
    @media (max-width: $sm) {
        width: 18px !important;
    }
    @media (min-width: $md) {
        width: 15px !important;
    }
    @media (min-width: $lg) {
        width: 15px !important;
    }
    @media (min-width: $xl) {
        width: 20px !important;
    }
}
.img_blanco_display{
    visibility: hidden !important;
    display: none !important;
    @media (max-width: $sm) {
        width: 18px !important;
    }
    @media (min-width: $md) {
        width: 18px !important;
    }
    @media (min-width: $lg) {
        width: 15px !important;
    }
    @media (min-width: $xl) {
        width: 20px !important;
    }
}

.nav-link.active {
    border-left: none !important;
    border-right: none !important;
    border-top: 1px solid #ACACAC !important;
    border-bottom: 1px solid #ACACAC !important;
    .item-menu {
        /* padding-top: ; */
        border-radius: 20px;
        background-color: $verde;
        box-shadow: 0px 1px 6px #00000045;
        border: 1px solid #ACACAC;
    }
    .material-icons {
        color: white !important;
    }
    .img_azul_display{
        visibility: hidden !important;
        display: none !important;
    }
    .img_blanco_display{
        visibility: visible !important;
        display: inline !important;
    }
}

.nav-link:hover {
    .item-menu {
        /* padding-top: ; */
        border-radius: 20px;
        background-color: $celeste-claro !important;
        color: $azul-claro !important;
    }
    .text-nav {
        font-weight: bold;
        color: $azul-claro !important;
    }
    .main-sidebar .nav .nav-item .nav-link i {
        color: $azul-claro !important;
    }
    .img_azul_display{
        visibility: visible !important;
        display: inline !important;
    }
    .img_blanco_display{
        visibility: hidden !important;
        display: none !important;
    }
}


