@import "../configs/color.css";

/* ---------------- ESTILOS GLOBALES DE IMPRESION ---------------------*/
@page {
    margin: 30px;
    size: portrait;
}
@media screen {
    .no-mostrar {
        display: none !important;
    }
}
@media print {
    @page {
        margin: 30px 30px;
        size: portrait
    }

    body,
    html,
    html body {
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeSpeed;
        color: #000;
        min-width: 0 !important;
        max-height: auto !important;
        -webkit-print-color-adjust: exact;
        background-color: #FFFFFF !important;
        -webkit-print-color-adjust: exact;
    }
    .col-cupones{
        /* -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%; */
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
    /* .hoja-carta{
        width: 21.6cm;
        height: 27.9cm;
        background-color: red;
    }
    .media-carta-arriba{
        max-width: 21.6cm;
        width: 21.6cm;
        max-height: 15.95cm;
        height: 15.95cm;
    }

    .media-carta-abajo{
        max-width: 21.6cm;
        width: 21.6cm;
        max-height: 11.95cm;
        height: 11.95cm;
    } */


    .dont-print {
        display: none !important
    }
    #PRINT-ROOT-CONTAINER {
        display: block !important;
    }

    .texto-cupon{
        font-size: 18px !important;
        font-family: 'Catamaran', sans-serif !important;
    }

    .text-equal{
        font-size: 8px !important;
    }

    .bold{
        font-weight: bolder !important;
        text-transform: uppercase;
        color: $azul;
    }

    .salto-pagina{
        height: 140px !important;
    }

    .impresion-horizontal{
        @page {
            margin: 30px;
            size: landscape
        }
    }
}
#PRINT-ROOT-CONTAINER {
    display: none;
}
