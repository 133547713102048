.react-responsive-modal-overlay {
    background: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: flex-start;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 107001 !important;
    padding: 1.2rem;
    @media (max-width: $sm) {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}
.react-responsive-modal-modal {
    margin: auto;
    min-width: 20vw !important;
    max-width: 70vw !important;
    min-height: 300px;
    position: relative;
    border-radius: 1.25rem;
    padding: 1.2rem;
    background: #ffffff;
    background-clip: padding-box;
    box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
    @media (max-width: $sm) {
        min-width: 100vw !important;
        min-height: 100vh;
        margin: auto !important;
    }
    .content-vmh-95 {
        height: 95vh !important;
    }
}
@media (min-width: $sm) {
    .react-responsive-modal-modal {
        .content-vmh-95 {
            height: 100% !important;
        }
    }
}
