
@import "../configs/color.css";

/*Notificaciones*/
.titulo {
    margin-bottom: 0.4rem;
}

.notification::before {
    color: white !important;
}

.notification-info:before {
    content: none;
}

.notification-info {
    background-color: $azul-marino !important;
    border: 2px solid $azul-marino !important;
    padding: 5px !important;
}

.noti-min-heigth {
    min-height: 70px;
}

.notification-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white !important;

    .title {
        font-weight: bold;
        font-size: 13px;
    }
}

.notification-dismiss {
    background-color: $rosa !important;
    width:  18px !important;
    height: 18px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px !important;
}
