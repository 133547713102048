
@import "configs/color";

.text-25 {
    font-size: 25 !important;
}

.ojito-login {
    position: absolute;
    /* top: 0%; */
    /* left: 10%; */
}

.circulo {
    height: 12px;
    width:  12px;
    border-radius: 50%;
}

/* Clases del mensaje de bienvenida */
.bienvenida {
    padding-left: 5rem;
    padding-right: 5rem;
    margin-bottom: 2rem;
    text-align: center;
}

.icono-tool-archivo {
    width: 80%;
}

/* BORDES */
.borde-gris-archivos {
    border-radius: 30px;
    border: 1px solid $gris-claro;
}

.borde-gris-notas {
    border: 1px solid $gris;
    border-radius: 15px;
}

.semi-redondeado {
    border-radius: 15px;
}

.blink-danger {
    top: 0 !important;
    animation: blinker 1s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

@media (max-width: 768px) {
    .bienvenida {
        padding-left:  0rem;
        padding-right: 0rem;
    }

    .icono-tool-archivo {
        width: 50%;
    }

    .view-perfil-head-title {
        align-items: center;
    }
}

/* PERFIL */

.view-perfil-head {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30vh;
    background-color: $azul-marino;
    border-radius: 1.5rem;
}

.view-perfil-head-img {
    height: 8.3rem;
    width:  8.3rem;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    background-color: $verde;
    img {
        height: 95%;
        width:  95%;
        border-radius: 50%;
    }
}

.view-perfil-barra {
    height: 8.8rem;
    width: 3px;
    background-color: $verde;
}

/* TABLA SIMPLE */
.sd-table {
    thead {
        background-color: $gris !important;
    }

    th {
        color: white;
        padding: 0.5rem 2rem;
        font-weight: bolder;
        text-transform: uppercase;
    }

    tbody {
        tr {
            background-color: white;
            border: 1px solid $gris-claro;
        }
    }

    td {
        padding: 0.5rem 2rem;
        color: $gris-oscuro;
    }

    .total-tabla {
        font-size: 15px;
    }

}

.bankia-logo {
    width: 127px;
    @media (max-width: 576px) {
        width: 100px !important;
    }
}

.text-cortado {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
