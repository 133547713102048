.videoUploader {
    align-items: center;
    background-color: #D1D8EC;
    cursor: pointer;
    display: flex;
    height: 150px;
    justify-content: center;
    border: 1px solid #ced4da;
    border-radius: 1rem;
    margin-top: 7px;
    position: relative;
    width: 100%;
}
.videoUploader input {
    display: none;
}
.videoUploader img, .videoUploader .icon {
    pointer-events: none;
}
.videoUploader, .videoUploader .icon {
    transition: all 100ms ease-in;
}
.videoUploader .icon {
    color: #eee;
    color: rgba(0, 0, 0, 0.2);
    font-size: 5em;
    width: 75px;
    z-index: 1;
    top: 40% !important;
}
.videoUploader img {
    left: 50%;
    opacity: 0;
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    top: 50%;
    transition: all 300ms ease-in;
    transform: translate(-50%, -50%);
    z-index: -1;
}

.videoUploader .loaded-texto {
    left: 50%;
    opacity: 0;
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    top: 50%;
    transition: all 300ms ease-in;
    transform: translate(-50%, -50%);
    z-index: -1;

    img {
        left: unset;
        opacity:unset;
        max-height:unset;
        max-width:unset;
        position:unset;
        top: unset;
        transition:unset;
        transform:unset;
        z-index:unset;
    }
}
.videoUploader .loaded-texto {
    opacity: 1 !important;
    z-index: 1;
}
.texto {
    margin-top: 95px;
}

.btn-image-picker {
    border: 1px solid transparent;
    border-radius: 0.75rem;
    padding: 0.4rem 2rem;
    font-size: .75rem;
    line-height: 1.125;
    font-weight: bold;
    font-family: 'Catamaran', sans-serif;
    justify-content: center;
    z-index: 1000;
    color: #fff;
    background-color: #686A75;
    border-color: #686A75;
}

.videoUploaderDocs{
    align-items: center;
    background-color: #8E8E8E;
    cursor: pointer;
    display: flex;
    height: 190px;
    justify-content: center;
    position: relative;
    width: 190px;
}
.videoUploaderDocs input {
    display: none;
}
.videoUploaderDocs img, .videoUploaderDocs .icon {
    pointer-events: none;
}
.videoUploaderDocs, .videoUploaderDocs .icon {
    transition: all 100ms ease-in;
}
.videoUploaderDocs .icon {
    color: #eee;
    color: rgba(0, 0, 0, 0.2);
    font-size: 5em;
    width: 150px;
    z-index: 1;
    top: 40% !important;
}
.videoUploaderDocs img {
    left: 50%;
    opacity: 0;
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    top: 50%;
    transition: all 300ms ease-in;
    transform: translate(-50%, -50%);
    z-index: -1;
}
.videoUploaderDocs img.loaded {
    opacity: 1 !important;
    z-index: 1;
}

.texto-blanco {
    margin-top: 150px;
    color: white;
}

.docs{
    width: 190px;
    height: 190px;
    margin-right: 20px;
    margin-bottom: 20px;
    border: 1px solid #8E8E8E;
    opacity: 1;
    display: block;
    transition: .5s ease;
    backface-visibility: hidden;
    background-color: transparent;
}

.img-container {
    /*position: relative;*/
    /*width: 50%;*/
}
.img-container:hover .docs {
    opacity: 0.3;
}
.img-container:hover .middle {
    opacity: 1;
}

.icon-delete {
    color: red;
    font-size: 16px;
    cursor: pointer;
    background: transparent;
    border: none !important;
}
.middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 8%;
    left: 75%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}
.docs img{
    width: 90%;
}

.docs img.zoom:hover{
    cursor: zoom-in;
}
