.reset-pass-container {
    height: 100vh !important;
    background-image: url("../../../../../../assets/img/fondo.png") ;
    /* background-color: #55c4d4; */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
}

.login-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0 !important;
}

.logo-reset {
    max-width: 200px !important;
}
