@import "../configs/color.css";

.rbc-calendar {
    width: 100% !important;
}

.rbc-time-view {
    width: 100% !important;
    background-color: white !important;
    @media (max-width: $sm) {
        overflow-x: scroll;
    }
}

.rbc-time-header.rbc-overflowing {
    @media (max-width: $sm) {
        width: 200%;
    }
}

.rbc-time-content {
    @media (max-width: $sm) {
        width: 200%;
    }
}

.calendario-toolbar {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 15px;
    background-color: white;
}

.rbc-btn {
    border: none !important;
    padding: 0 !important;
    height: 25px !important;
    width:  25px !important;
    border-radius: 50% !important;
    background-color: $celeste !important;
}

.rbc-label-fecha {
    span, b {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 15px !important;
    }
}

.rbc-label.rbc-time-header-gutter {
    /* border-right: none !important; */
    /* background-color: $azul-claro !important; */
}

.rbc-time-gutter.rbc-time-column {
    align-items: center !important;
}

.rbc-time-header-content {
    /* border-left: none !important; */
}

.rbc-row.rbc-time-header-cell {
    color: white;
    background-color: $azul-claro !important;
}

.rbc-header {
    border-left:  none !important;
    border-right: none !important;
}

.rbc-time-slot {
    text-align: end !important;
    font-weight: 300 !important;
    /* color: white; */
}

.rbc-events-container {
    margin: 0 !important;
}

.rbc-event {
    border-radius: 0 !important;
}

.rbc-time-header-content {
    .rbc-day-bg {
        border: none !important;
    }
}

.rbc-event {
    opacity: 0.75;
    border: 1px solid white !important;
}

.rbc-header.rbc-today {
    font-weight: bold !important;
    color: $azul-marino !important;
    border: none !important;
}


.rbc-temario {
    width:  26px;
    height: 26px;
    position: absolute;
    opacity: 100%;
    background-color: transparent;
    top: 0;
    right: 1px;
}

.event-full {
    width: 100% !important;
    left: 0 !important;
}

