@import '../../../../../style/configs/color.css';

.main-content .header-navbar, .main-content > .main-navbar-fixed {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.main-navbar-fixed{
    border: none !important;
}

.nav-link-icon {
    color: $azul-claro !important;
}

.nav-link {
    border: none !important;
}
