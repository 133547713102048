@import "./configs/color.css";

@media only screen and (max-width: 768px) {
  .row {
    margin-left: 0;
    margin-right: 0;
  }
}

/* Bootstrap table */
.react-bs-container-body {
    overflow-x: hidden;
    /* overflow-x: scroll !important; */
    /* @media (min-width: $sm) { */
    /* } */
}
.react-bs-container-body {
    td {
        white-space: normal !important;
    }
}
.react-bs-table {
    border: none !important;
    thead {
        padding: 0.2rem !important;
        color: white;
        background-color: $azul-claro !important;
    }
    th {
        padding-top:    0.5rem !important;
        padding-bottom: 0.5rem !important;
        text-transform: uppercase;
        font-weight: bold !important;
        font-size: 14px !important;
    }
    th:first-child {
        border-top-left-radius: 8px !important;
    }
    th:last-child {
        border-top-right-radius: 8px !important;
    }
    th, td, tr {
        border: none !important;
    }
    td {
        font-weight: 500 !important;
        font-size: 14px !important;
        color: $gris-oscuro !important;
    }
    tbody {
        /* td {
            white-space: normal;
        } */
        tr:nth-child(2n + 1) {
            background-color: white !important;
            border-top:    solid 2px transparent;
            border-bottom: solid 2px transparent;
            border-left:   solid 12px transparent;
            border-right:  solid 4px transparent ;
        }
        tr:first-child{
            border-top:    solid 2px transparent !important;
            border-bottom: solid 2px transparent;
            border-left:   solid 6px transparent !important;
            border-right:  solid 2px transparent !important;
        }
        tr:nth-child(2n) {
            background-color: $celeste-claro;
            border-top:    solid 2px transparent;
            border-bottom: solid 2px transparent;
            border-left:   solid 12px transparent;
            border-right:  solid 4px transparent ;
        }
        tr:last-child {
            border-bottom: solid 3px $celeste-claro;
        }
        tr:hover{
            border-top:    solid 2px $verde !important;
            border-bottom: solid 2px $verde !important;
            border-left:   solid 12px $verde !important;
            border-right:  solid 4px $verde  !important;
            td:first-child {
                padding: .15rem 0.4rem !important;
            }

            /* Resaltado de las acciones en las tablas */
            .editar-action {
                background-color: $verde !important;
                border-radius: 7px;
                color: white !important;
                padding: 3px;
            }
            .eliminar-action {
                background-color: $azul-marino !important;
                border-radius: 7px;
                color: white !important;
                padding: 3px;
            }
            .ver-action {
                background-color: $azul-marino !important;
                border-radius: 7px;
                color: white !important;
                padding: 3px;
            }
            .asignar-action {
                background-color: $azul-claro !important;
                border-radius: 7px;
                color: white !important;
                padding: 3px;
            }
            .anular-action {
                background-color: #9C023B !important;
                border-radius: 7px;
                color: white !important;
                padding: 3px;
            }
        }
        tr:first-child:hover{
            border-top:    solid 2px $verde !important;
            border-bottom: solid 2px $verde !important;
            border-left:   solid 6px $verde !important;
            border-right:  solid 2px $verde  !important;
            td:first-child {
                padding: .15rem 0.4rem !important;
            }
        }
    }
}
.react-bs-table-pagination {
    .row {
        div:first-child {
            display: none !important;
        }
        div:last-child {
            display: flex !important;
        }
    }
}

@media only screen and (max-width: 768px) {
    .react-bs-container-body {
        overflow-x: scroll !important;
    }
    .react-bs-table-container .table {
        min-width: 900px !important;
        /* max-width: 2000px !important; */
    }
}
