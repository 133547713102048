/* Colores base */
$color-title: #721c24;
$color-navbar: #006FBD;
$primary: #007bff;

$azul-marino: #1C375E;
$azul: #0336B5;
$azul-claro: #366AED;
$verde: #53A513;
$verde-claro: #5ACE00;
$gris-oscuro: #686A75;
$gris: #8C8F9B;
$gris-claro: #BABFCE;
$celeste: #D1D8EC;
$celeste-claro: #DBE4FC;
$rosa: #E9206A;
$blanco: #FFF;
$aqua: #2CB2E5;
$mandarina: #FF8000;
$naranja: #FFC400;
$facebook: #1560D1;
$google: #CB2727;

/* Clases de colores para texto */
.azul-marino{
    color: $azul-marino;
}
.azul{
    color: $azul;
}
.azul-claro{
    color: $azul-claro;
}
.verde{
    color: $verde;
}
.verde-claro{
    color: $verde-claro;
}
.gris-oscuro{
    color: $gris-oscuro;
}
.gris{
    color: $gris;
}
.gris-claro{
    color: $gris-claro;
}
.celeste{
    color: $celeste;
}
.celeste-claro{
    color: $celeste-claro;
}
.rosa{
    color: $rosa;
}
.blanco{
    color: $blanco !important;
}
.aqua{
    color: $aqua;
}
.naranja{
    color: $naranja;
}
.mandarina{
    color: $mandarina;
}
.facebook{
    color: $facebook;
}
.google{
    color: $google;
}
/* Fondos de los colores */

.bg-azul-marino{
    background-color: $azul-marino;
}
.bg-azul{
    background-color: $azul;
}
.bg-azul-claro{
    background-color: $azul-claro;
}
.bg-verde{
    background-color: $verde;
}
.bg-verde-claro{
    background-color: $verde-claro;
}
.bg-gris-oscuro{
    background-color: $gris-oscuro;
}
.bg-gris{
    background-color: $gris;
}
.bg-gris-claro{
    background-color: $gris-claro;
}
.bg-celeste{
    background-color: $celeste;
}
.bg-celeste-claro{
    background-color: $celeste-claro;
}
.bg-rosa{
    background-color: $rosa;
}
.bg-blanco{
    background-color: $blanco;
}
.bg-aqua{
    background-color: $aqua;
}
.bg-naranja{
    background-color: $naranja;
}
.bg-mandarina{
    background-color: $mandarina;
}
.bg-facebook{
    background-color: $facebook;
}
.bg-google{
    background-color: $google;
}
