@import '../../../../../style/configs/breakpoints.css';

.login-container {
    height: 100vh;
    overflow-x: hidden !important;
}
.card-conteiner {
    display: flex;
    width: 100%;
}
.login-overlay .panel-body{
    min-height: 244px;
}
.has-feedback{
    position: relative;
}
.form-control-feedback{
    right: 10px;
    left: auto;
    top: 10px;
    position: absolute;
}
.login-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}
.card-login {
    border: 0;
    border-radius: 294px 0px 0px 294px !important;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
    padding: 3rem 0rem;
    margin: 0 4rem;
    box-shadow: 0px 10px 30px #0000003B !important;
    width: 100% !important;
    height: 100% !important;
    right: -100%;
}
.card-login.animado {
    right: 0% !important;
    -webkit-transition: right 1.5s;
    transition: right 1.5s;
    -moz-transition: right 1.5s;
    -ms-transition: right 1.5s;
    -o-transition: right 1.5s;
}
.card-login-responsive {
    border: 0;
    border-radius: 0px 0px 10rem 10rem !important;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
    margin: 0 1rem;
    box-shadow: 0px 10px 30px #0000003B !important;
    height: 100% !important;
    width: 100%;
    top: -100%;
}
.card-login-responsive.animado {
    top: 0%;
    -webkit-transition: top 1s;
    transition: top 1;
    -moz-transition: top 1s;
    -ms-transition: top 1s;
    -o-transition: top 1s;
}
.logo-card-login {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.logo-login {
    position: absolute;
    right: -100%;
    width: 60vw;
    height: 100vh;
}
.logo-login.animado {
    right: 0%;
    -webkit-transition: right 1s;
    transition: right 1s;
    -moz-transition: right 1s;
    -ms-transition: right 1s;
    -o-transition: right 1s;
}
.img-logo-login{
    z-index: 400000;
    width: 100%;
    height: 100%;
}
.form-login {
    width: 50%;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    padding-left:  8rem;
    padding-right: 6rem;
}
.bienvenida{
    color:white;
    font-weight: bold;
}
.buttons-box{
    display: flex;
    align-items: center;
    justify-content: center;
}


@media (max-width: $xl) {
    .form-login {
        padding-left:  4rem;
        padding-right: 2rem;
    }
}

@media (max-width: $lg) {
    .login-container {
        /* height: 100%; */
        /* background-image: url('../../../../../assets/img/logo_login_movil.png'); */
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .card-conteiner {
        /* display: none; */
        position: absolute;
        z-index: 1;
        height: 65%;
        top: 0;
    }

    .card-login {
        margin: 0 !important;
    }

    .logo-login-responsive {
        position: absolute;
        height: 100%;
        width : 100%;

        img {
            height: 100%;
            width : 100%;
        }
    }

    .logo-card-login {
        margin-left : auto;
        margin-right: auto;
        padding-top: 1rem;
    }

    .form-login {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
        align-items: center;
        padding: 0 2rem;
        /* padding-left:  10rem; */
        /* padding-right: 8rem; */
    }
}
