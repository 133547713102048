@import "../configs/color.css";

.btn{
    border: 1px solid transparent !important;
    border-radius: 0.75rem !important;
    padding: 0.4rem 2rem !important;
    font-size: .75rem !important;
    line-height: 1.125 !important;
    font-weight: bold !important;
    font-family: 'Catamaran', sans-serif !important;
    justify-content: center !important;
    z-index: 1000 !important;
}

/* Botones solidos */
.btn-primary {
    color: #fff;
    background-color: $verde-claro;
    border-color: $verde-claro;
}

.btn-primary:hover {
    color: #fff;
    background-color: $verde;
    border-color: $verde;
}

.btn-primary:focus {
    color: #fff;
    background-color: $verde;
    border-color: $verde;
    box-shadow: none !important;
}

.btn-primary:disabled {
    color: #fff;
    background-color: #3d8b01;
    border-color: #3d8b01;
    box-shadow: none !important;
}

.btn-secondary {
    color: #fff;
    background-color: $azul-marino;
    border-color: $azul-marino;
}

.btn-secondary:hover {
    color: #fff;
    background-color: $azul-claro;
    border-color: $azul-claro;
}

.btn-secondary:focus {
    color: #fff;
    background-color: $azul-claro;
    border-color: $azul-claro;
    box-shadow: none !important;
}

.btn-dark {
    color: #fff;
    background-color: $gris-oscuro;
    border-color: $gris-oscuro;
}

.btn-dark:hover {
    color: #fff;
    background-color: $gris;
    border-color: $gris;
}

.btn-dark:focus {
    color: #fff;
    background-color: $gris;
    border-color: $gris;
    box-shadow: none !important;
}

/* Eliminar formulario */
.btn-eliminar {
    color: $azul-claro;
    background-color: $celeste-claro;
    border-color: $celeste-claro;
}

.btn-eliminar:hover {
    color: $azul-claro;
    background-color: $celeste-claro;
    border-color: $celeste-claro;
}

.btn-eliminar:focus {
    color: $azul-claro;
    background-color: $celeste-claro;
    border-color: $celeste-claro;
    box-shadow: none !important;
}

/* Redes sociales */
.btn-facebook {
    color: #fff !important;
    background-color: $facebook !important;
    border-color: $facebook !important;

    @media (max-width: $sm) {
        font-size: 10px !important;
    }
}

.btn-facebook:hover {
    color: #fff !important;
    background-color: #1254b6 !important;
    border-color: #1254b6 !important;
}

.btn-facebook:focus {
    color: #fff !important;
    background-color: #1254b6 !important;
    border-color: #1254b6 !important;
    box-shadow: none !important;
}

.btn-google {
    color: #fff !important;
    background-color: $google !important;
    border-color: $google !important;

    @media (max-width: $sm) {
        font-size: 10px !important;
    }
}

.btn-google:hover {
    color: #fff !important;
    background-color: #ad2020 !important;
    border-color: #ad2020 !important;
}

.btn-google:focus {
    color: #fff !important;
    background-color: #ad2020 !important;
    border-color: #ad2020 !important;
    box-shadow: none !important;
}

/* Botones con borde (Outline) */
.btn-outline-primary {
    background-color: white;
    border: 1px solid $verde-claro !important ;
    color: $verde-claro;
}

.btn-outline-primary:hover {
    background-color: $verde-claro;
    border-color: $verde-claro;
    color: white;
}

.btn-outline-primary:focus {
    background-color: $verde-claro;
    border-color: $verde-claro;
    color: white;
    box-shadow: none !important;
}

.btn-outline-secondary {
    background-color: white;
    border: 1px solid $azul-marino !important ;
    color: $azul-marino;
}

.btn-outline-secondary:hover {
    background-color: $azul-claro;
    border-color: $azul-claro;
    color: white;
}

.btn-outline-secondary:focus {
    background-color: $azul-claro;
    border-color: $azul-claro;
    color: white;
    box-shadow: none !important;
}

/* Nuevos */
.btn-alter1 {
    background-color: white;
    font-weight: 600 !important;
    border: 1px solid $celeste !important ;
    color: $rosa;
    box-shadow: 0px 3px 6px #00000029;
}

.btn-alter1:hover {
    background-color: $rosa;
    border-color: $rosa;
    color: white;
}

.btn-alter1:focus {
    background-color: $rosa;
    border-color: $rosa;
    color: white;
    box-shadow: none !important;
}

/* Otros */
.btn-password {
    background-color: transparent !important;
    border: none !important;
    position: absolute;
    /* left: 90%; */
    right: 5%;
    bottom: 0%;
}

.btn-password:focus {
    outline: none !important;
}

.btn-delete {
    background-color: transparent !important;
    border: none !important;
    padding: 0 !important;
    margin: 0 !important;
}
.btn-delete:focus {
    outline: none !important;
}

.btn-add {
    border-radius: 50% !important;
    color: $azul-claro !important;
    background-color: $celeste-claro !important;
    border-color: transparent !important;
    padding: 0 !important;
    margin: 0 !important;
}

.btn-add:focus {
    outline: none !important;
    border-radius: 50% !important;
    color: $azul-claro !important;
    background-color: $celeste-claro !important;
    border-color: transparent !important;
    padding: 0 !important;
    margin: 0 !important;
}
