@import "../configs/color.css";

/* Accordion */
.MuiAccordion-rounded:first-child {
    border-top-left-radius: unset !important;
    border-top-right-radius: unset !important;
}

.MuiPaper-elevation1 {
    box-shadow: none !important;
}

.MuiPaper-rounded {
    border-radius: 4px;
}

.MuiPaper-root {
    color: $gris-oscuro !important;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #fff;
}

.MuiButtonBase-root {
    border-bottom: 2px solid $gris-oscuro !important;
    cursor: pointer !important;
}

.MuiAccordionDetails-root {
    display: flex !important;
    padding: 8px 0 16px 0 !important;
    @media (max-width: $sm) {
        padding: 8px 0 16px 0 !important;
    }
}

.MuiAccordion-root.Mui-expanded {
    margin: 0;
}

.MuiIconButton-root {
    border-radius: 50%;
    border: 0 !important;
}
