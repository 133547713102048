.load-texto {
    font-size: 3rem;
    color: white;
    margin-top: 2.5rem;
    font-weight: bold;
}

@keyframes color {
    0%{
        color: #366AED;
    }
    50%{
        color: #53A513;
    }
    100%{
        color: #366AED;
    }
}
