.clickable-element {
    cursor: pointer;
}

.sin-padding-responsive{
    @media (max-width: $sm) {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
}

/* Clases de cupones */
.cuadro-cupon{
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 0.5rem;
    border: 1px solid $verde;
}

.borde-cupon{
    border-radius: 0.5rem;
    border: 1px solid $gris-oscuro;
}

.imagen-cupon{
    max-width: 100%;
    height: auto;
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem;
}

/* ESTRELLAS DE LA CALIFICACION */
.MuiRating-root {
    color: $azul-claro !important;
    cursor: pointer;
    display: inline-flex;
    position: relative;
    font-size: 2.5rem;
    text-align: left;
    -webkit-tap-highlight-color: transparent;

    span{
        font-size: unset !important;
    }
    label{
        font-size: unset !important;
    }
}

.MuiRating-iconEmpty {
    color: $celeste !important;
}

.error-rating{
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}

/* Imagen de la modal de comentario */
.img-avatar-comentario{
    max-width: 150px;
    height: auto;
    border-radius: 50%;
    border: 3px solid $verde !important;

    @media (max-width: $sm) {
        max-width: 100% !important;
    }
}

/* Responsive video tag */
video {
    width: 100%;
    height: auto;
}

/* Etiqueta para los parrafos de las noticias */
.parrafo-corto{
    white-space: nowrap !important;
    text-overflow: ellipsis;
    overflow: hidden;
}
