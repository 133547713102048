@import '../configs/color.css';

.step-number{
    background: white;
    border: 1px solid;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    font-size: 12px;
    font-weight: bold;
}

.step-dot{
    background: $azul-claro;
    border: 1px solid;
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    font-size: 12px;
    font-weight: bold;
}

.step-checked{
    background: $azul-claro;
    border: 1px solid $azul-claro;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    font-size: 12px !important;
    font-weight: bold;
}
